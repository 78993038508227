import React from "react";
import "./ItemCard.scss";

const ItemCard = ({ title, logo, image }) => (
  <div className="item-card">
    <div className="item-card-content">
      <img src={logo} alt={title} className="logo" />
      <h2>{title}</h2>
    </div>
    <img className="backdrop" src={image} alt={title} />
  </div>
);

export default ItemCard;
