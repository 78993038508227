import { useState, useEffect } from "react";

import "./App.css";
import { Title, Container, ItemCard } from "./UI";

import bgImage from "./assets/images/background.png";

function App() {
  const query = `
    {
      restaurantCollection {
        items {
          name
          logo {
            url
          }
          backdrop {
            url
          }
        }
      }
    }
    `;

  const [restaurants, setRestaurants] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/8wo560tvtz9k/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer U-N3uonNBOjj77nIYsyWW1iXbxjfM8UvKMkufASjSeY",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data);
        setRestaurants(data.restaurantCollection.items);
        // setPage(data.pageCollection.items[0]);
      });
  }, []);
  // show a loading screen case the data hasn't arrived yet
  if (!restaurants) {
    return "Loading...";
  }

  return (
    <div className="App" style={{ backgroundImage: `url(${bgImage})` }}>
      <Title title={"Best Restaurants in Reykjavik"} />
      <Container>
        {restaurants.map((restaurant) => (
          <ItemCard
            logo={restaurant.logo.url}
            image={restaurant.backdrop.url}
          />
        ))}
      </Container>
    </div>
  );
}

export default App;
